import {
	AppointmentType,
	CheckInData,
	TeloAppointment,
	GeminiAppointmentType,
} from '../model/appointment'
import {
	AppointmentStatus,
	Exam,
	ExamStatus,
	IntakeFormUser,
	StrippedExam,
} from '../model/exam'
import { FirstDataModel, TypePhoneNumber } from '../model/model'
import { YesNoValue } from '../model/types'
import { examEndedStatus } from './exams'
import { isEmeaRegion } from './region'
import { keys } from './utils'

interface Step2InitialValues {
	presentGlassAge: string
	lastExamDate: Date | null
	eyesDilatatedBefore: YesNoValue
	retinalImageBefore: YesNoValue
	pregnant: YesNoValue
	smoke: YesNoValue
	drink: YesNoValue
	medications: YesNoValue
	allergic: YesNoValue
}

export const calculateAppointmentStatus = (
	appointment: TeloAppointment,
	exam?: Exam | StrippedExam,
): ExamStatus => {
	if (!exam) {
		return appointment.externalStatus as AppointmentStatus
	} else {
		return exam.status
	}
}

export const extractFirstDataFromAppointment = (
	appointment: CheckInData,
): FirstDataModel => {
	const {
		id,
		name,
		middleName,
		surname,
		birthDate,
		phoneNumber,
		typePhoneNumber,
		email,
	} = appointment.patient
	return {
		id,
		name,
		middleName,
		surname,
		birthDate,
		phoneNumber: phoneNumber || '',
		typePhoneNumber: typePhoneNumber
			? (typePhoneNumber as TypePhoneNumber)
			: '',
		email,
	}
}

// export const tbdStatus: ExamStatus[] = ['NotShown', 'Upcoming', 'Arrived']

export const inProgressStatus: ExamStatus[] = [
	'IntakeFormEnded',
	'PreTestStarted',
	'PreTestFinished',
	'RefractionistStarted',
	'RefractionistEnded',
	'DoctorModeSelected',
	'DoctorStarted',
	'Paused',
	'Waiting',
]

export const getStep2InitialValues = (
	step2InitialValues: Step2InitialValues,
	results: IntakeFormUser,
) => {
	const step2Keys = keys(step2InitialValues)
	return step2Keys.reduce((result, key) => {
		result[key] = results[key]
		return result
	}, {} as { [k: string]: string | Date | YesNoValue | null })
}

const EMEA_APPOINTMENT_TYPES: AppointmentType[] = [
	'PR.NUCCI PAOLO AMB CONTROLLO PRIMARIO',
	'PR.NUCCI VALUTAZIONE ORTOTTICA',
	'PROF. NUCCI',
	'PROF. NUCCI AMB VAL.ORTOTTICHE',
]
export const newAppointmentTypes = isEmeaRegion
	? EMEA_APPOINTMENT_TYPES
	: [
			'Eye exam',
			'Medical eye exam',
			'Renewal prescription',
			'CL exam',
			'CL follow up',
	  ]

export const appointmentTypes: AppointmentType[] = isEmeaRegion
	? EMEA_APPOINTMENT_TYPES
	: [
			'COMP_VIS',
			'CONS/PRE',
			'DE',
			'DIAB_EX',
			'DMV_TEST',
			'DRI',
			'EP CL',
			'EP CL NW',
			'EP E',
			'EP ONESIGHT',
			'NP CL',
			'NP CL NW',
			'NP EEX',
			'NP ONESIGHT',
			'CL CK 14',
			'CL CK 7',
			'CL CK 6M',
			'RX CK',
			'EX CL RF',
			'F',
			'MED_EX',
			'CL FIT',
			'CL TRAIN',
			'CAT FU',
			'REF Sx FU',
			'SPEC_CL_FIT',
			'SPEC_CL_CK',
			'VT EX',
			'VT EVAL',
			'DFE',
			'DRY_CK',
			'DRY_TREAT',
			'EP_MED',
			'GLAUC_TEST',
			'IOP',
			'NP_MED',
			'RED EYE',
			'VF',
			'A10',
			'A20',
			'A30',
			'A40',
			'A50',
			'A60',
			'CL20',
			'CL30',
			'CL40',
			'W-CL',
			'W-INT',
			'DEFERRED',
			'DFE10',
			'DFE20',
			'DFE30',
			'DFE40',
			'DRIVE',
			'EM10',
			'EM20',
			'EM30',
			'HEALTH30',
			'HEALTH40',
			'RECH',
			'RV10',
			'RV20',
			'RV30',
			'SECOND20',
			'SECOND30',
			'TELEOPTA',
			'TELEOPTV',
			'EC',
			'EP_DIABET',
			'EP_DRY',
			'EP_MED_CK',
			'EX CL F',
			'INFT SEE',
			'LASIK',
			'LOW_VIS',
			'LSK CONS',
			'NP_DIABET',
			'NP_DRY',
			'NP_MED_CK',
			'OCT',
			'OV',
			'PRK/AST',
			'SAME_D',
			'VT CK',
			'WALK_IN',
	  ]

export const TAB_APPOINTMENT_TYPES: {
	key: string
	code: string
	label: string
}[] = [
	{
		key: 'CAT_FU',
		code: 'CAT FU',
		label: 'Cataract Surgery Follow Up',
	},
	{
		key: 'CL_CK_14',
		code: 'CL CK 14',
		label: 'Check - Contact Lens 14 day',
	},
	{
		key: 'CL_CK_7',
		code: 'CL CK 7',
		label: 'Check - Contact Lens 7 day',
	},
	{
		key: 'CL_FIT',
		code: 'CL FIT',
		label: 'Fitting - Contact Lens',
	},
	{
		key: 'CL_TRAIN',
		code: 'CL TRAIN',
		label: 'Training - Contact Lens',
	},
	{
		key: 'COMP_VIS',
		code: 'COMP_VIS',
		label: 'Computer Vision',
	},
	{
		key: 'CONS/PRE',
		code: 'CONS/PRE',
		label: 'Consult/Pre',
	},
	{
		key: 'DE',
		code: 'DE',
		label: 'Doctor Evaluation',
	},
	{
		key: 'DFE',
		code: 'DFE',
		label: 'Check - Dilated Fundus',
	},
	{
		key: 'DIAB_EX',
		code: 'DIAB_EX',
		label: 'Diabetic Exam',
	},
	{
		key: 'DMV_TEST',
		code: 'DMV_TEST',
		label: 'Check - DMV Testing',
	},
	{
		key: 'DRI',
		code: 'DRI',
		label: 'Digital Retinal Imaging',
	},
	{
		key: 'DRY_CK',
		code: 'DRY_CK',
		label: 'Check - Dry Eye Follow Up',
	},
	{
		key: 'DRY_TREAT',
		code: 'DRY_TREAT',
		label: 'Check - Dry Eye Treatment',
	},
	{
		key: 'EC',
		code: 'EC',
		label: 'Eyewear Consultant',
	},
	{
		key: 'EP_CL_NW',
		code: 'EP CL NW',
		label: 'Existing Patient - Contact Lens - New  Wearer',
	},
	{
		key: 'EP_CL',
		code: 'EP CL',
		label: 'Existing Patient - Contact Lens',
	},
	{
		key: 'EP_E',
		code: 'EP E',
		label: 'Existing Patient - Eye Exam',
	},
	{
		key: 'EP_ONESIGHT',
		code: 'EP ONESIGHT',
		label: 'Existing Patient - OneSight',
	},
	{
		key: 'EP_DIABET',
		code: 'EP_DIABET',
		label: 'Existing Patient - Diabetic Eye Exam',
	},
	{
		key: 'EP_DRY',
		code: 'EP_DRY',
		label: 'Existing Patient - Dry Eye Exam',
	},
	{
		key: 'EP_MED_CK',
		code: 'EP_MED_CK',
		label: 'Check - Existing Patient - Medical Eye Follow Up',
	},
	{
		key: 'EP_MED',
		code: 'EP_MED',
		label: 'Existing Patient - Medical Eye Exam',
	},
	{
		key: 'EX_CL_F',
		code: 'EX CL F',
		label: 'Exam - Contact Lens Fit',
	},
	{
		key: 'EX_CL_RF',
		code: 'EX CL RF',
		label: 'Exam - Contact Lens Refit',
	},
	{
		key: 'F',
		code: 'F',
		label: 'Exam - Follow Up',
	},
	{
		key: 'GLAUC_TEST',
		code: 'GLAUC_TEST',
		label: 'Check - Glaucoma Testing',
	},
	{
		key: 'INFT_SEE',
		code: 'INFT SEE',
		label: 'Infant See',
	},
	{
		key: 'IOP',
		code: 'IOP',
		label: 'Check - Intraocular Pressure',
	},
	{
		key: 'LASIK',
		code: 'LASIK',
		label: 'Lasik',
	},
	{
		key: 'LOW_VIS',
		code: 'LOW_VIS',
		label: 'Low Vision',
	},
	{
		key: 'LSK_CONS',
		code: 'LSK CONS',
		label: 'Lasik Consult',
	},
	{
		key: 'MED_EX',
		code: 'MED_EX',
		label: 'Medical Eye Exam',
	},
	{
		key: 'NP_CL_NW',
		code: 'NP CL NW',
		label: 'New Patient - Contact Lens - New Wearer',
	},
	{
		key: 'NP_CL',
		code: 'NP CL',
		label: 'New Patient - Contact Lens',
	},
	{
		key: 'NP_EEX',
		code: 'NP EEX',
		label: 'New Patient - Eye Exam',
	},
	{
		key: 'NP_ONESIGHT',
		code: 'NP ONESIGHT',
		label: 'New Patient - OneSight',
	},
	{
		key: 'NP_DIABET',
		code: 'NP_DIABET',
		label: 'New Patient - Diabetic Eye Exam',
	},
	{
		key: 'NP_DRY',
		code: 'NP_DRY',
		label: 'New Patient - Dry Eye Exam',
	},
	{
		key: 'NP_MED_CK',
		code: 'NP_MED_CK',
		label: 'Check - New Patient - Medical Eye Follow Up',
	},
	{
		key: 'NP_MED',
		code: 'NP_MED',
		label: 'New Patient - Medical Eye Exam',
	},
	{
		key: 'OCT',
		code: 'OCT',
		label: 'Ocular Coherence Tomography',
	},
	{
		key: 'OV',
		code: 'OV',
		label: 'Office Visit',
	},
	{
		key: 'PRK/AS',
		code: 'PRK/AST',
		label: 'PRK/AST',
	},
	{
		key: 'RED_EYE',
		code: 'RED EYE',
		label: 'Red Eye',
	},
	{
		key: 'REF_Sx_FU',
		code: 'REF Sx FU',
		label: 'Refractive Surgery Follow Up',
	},
	{
		key: 'RX_CK',
		code: 'RX CK',
		label: 'Check - Rx Ck',
	},
	{
		key: 'SAME_D',
		code: 'SAME_D',
		label: 'Same day',
	},
	{
		key: 'SPEC_CL_CK',
		code: 'SPEC_CL_CK',
		label: 'Check - Specialty Contact Lens Fit',
	},
	{
		key: 'SPEC_CL_FIT',
		code: 'SPEC_CL_FIT',
		label: 'Exam - Specialty Contact Lens Fit',
	},
	{
		key: 'VF',
		code: 'VF',
		label: 'Check - Visual Field',
	},
	{
		key: 'VT_CK',
		code: 'VT CK',
		label: 'Vision Therapy Check',
	},
	{
		key: 'VT_EVAL',
		code: 'VT EVAL',
		label: 'Vision Therapy Evaluation',
	},
	{
		key: 'VT_EX',
		code: 'VT EX',
		label: 'Vision Therapy Session',
	},
	{
		key: 'WALK_IN',
		code: 'WALK_IN',
		label: 'Walk-ins',
	},
]

export const geminiAppointmentTypes: GeminiAppointmentType[] = [
	'Comprehensive New',
	'Comprehensive Est',
	'Comprehensive/CL New',
	'Comprehensive/CL Est',
	'Post-op',
	'Spectacle Re-check',
	'Special Testing',
	'Evaluation & Management',
	'Intermediate New',
	'Intermediate Est',
]

export const clAppointmentTypes: AppointmentType[] = [
	'CL CK 7',
	'CL CK 14',
	'CL CK 6M',
	'RX CK',
]

// ref for Appointment Types https://abstractsrl.atlassian.net/browse/TEL-4916
export const clImportPastDataAppointments: AppointmentType[] = [
	'CL CK 7',
	'CL CK 14',
]

export const rxAppointmentTypes: AppointmentType[] = ['RX CK']

export const isJustStartedFollowUp: <
	T extends Pick<Exam, 'examType' | 'history' | 'status'>,
>(
	exam: T | undefined,
) => boolean = <T extends Pick<Exam, 'examType' | 'history' | 'status'>>(
	exam: T | undefined,
) => {
	if (!exam || !exam.examType || !clAppointmentTypes.includes(exam.examType)) {
		return false
	}

	const isJustStartedFollowUpValue =
		exam.history.length === 1 &&
		!exam.history[0].prevStatus &&
		examEndedStatus.includes(exam.history[0].currentStatus)

	const isJustStartedFollowUpWithPhoropterValue =
		exam.history.length === 2 &&
		!exam.history[0].prevStatus &&
		exam.status === 'DoctorModeSelected'

	return isJustStartedFollowUpValue || isJustStartedFollowUpWithPhoropterValue
}

export function applyStatusOverrides(
	status: ExamStatus,
	exam: Pick<Exam, 'examType' | 'history' | 'status'> | undefined,
): ExamStatus {
	if (exam && isJustStartedFollowUp(exam)) {
		return 'PreTestFinished'
	}
	return status
}
