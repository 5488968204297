import { Privilege, Role } from './users'

export type DRAWER_TABS =
	| 'config'
	| 'missionControl'
	| 'users'
	| 'stores'
	| 'panels'
	| 'practice'
	| 'reports'
	| 'myPanels'

export type DRAWER_TAB = {
	tab: DRAWER_TABS
	label: string
	ref: string
	enabledUser: (Role | Privilege)[]
}

export const CP_DRAWER_TABS: DRAWER_TAB[] = [
	{
		tab: 'config',
		label: 'admin.generalConfig',
		ref: '/admin',
		enabledUser: [
			'TechnicalAdmin',
			'GlobalAdmin',
			'Doctor',
			'Technician',
			'FrontDesk',
		],
	},
	{
		tab: 'missionControl',
		label: 'admin.missionControl',
		ref: '/admin',
		enabledUser: ['TechnicalAdmin', 'GlobalAdmin', 'FrontDesk'],
	},
	{
		tab: 'users',
		label: 'admin.users',
		ref: '/admin',
		enabledUser: ['TechnicalAdmin', 'GlobalAdmin', 'FrontDesk'],
	},
	{
		tab: 'stores',
		label: 'admin.store',
		ref: '/admin',
		enabledUser: [
			'TechnicalAdmin',
			'GlobalAdmin',
			'StoreManager',
			'SetupManager',
			'FrontDesk',
		],
	},
	{
		tab: 'practice',
		label: 'admin.practice',
		ref: '/admin',
		enabledUser: ['TechnicalAdmin', 'GlobalAdmin', 'FrontDesk'],
	},
	{
		tab: 'panels',
		label: 'admin.panel',
		ref: '/admin',
		enabledUser: ['GlobalAdmin', 'FrontDesk'],
	},
	{
		tab: 'myPanels',
		label: 'admin.myPanel',
		ref: '/admin',
		enabledUser: ['Doctor'],
	},
	{
		tab: 'reports',
		label: 'admin.exportExams',
		ref: '/admin',
		enabledUser: [
			'TechnicalAdmin',
			'GlobalAdmin',
			'ReportManager',
			'FrontDesk',
		],
	},
]
