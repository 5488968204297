import appConfig from '../config'
import authActions from '../features/auth/actions'
import { Role } from '../model/users'
import store from '../store'
import { getEclipsMetadata, getIntersessionInStore } from './intersession'

export const getUserDashboard = (
	userRole: Role,
	storeId?: string,
	teloEnabled: boolean = true,
	renewalPrescriptionEnabled: boolean = false,
) => {
	const inStore = getIntersessionInStore()
	const WOKRLIST_URL = `/store/${storeId}/worklist`
	const STORE_SELECTION_URL = '/store-selection'
	const doctorUrl = teloEnabled
		? inStore
			? WOKRLIST_URL
			: '/doctor/worklist'
		: renewalPrescriptionEnabled
		? '/doctor/renewal/dashboard'
		: '/grants'

	const worklistOrStoreSelect = !storeId ? STORE_SELECTION_URL : WOKRLIST_URL
	const rolesAndRedirect: { role: Role; redirect: string }[] = [
		{ role: 'Doctor', redirect: doctorUrl },
		{ role: 'Refractionist', redirect: `/refractionist/dashboard` },
		{ role: 'Technician', redirect: `/store/${storeId}/worklist` },
		{ role: 'FrontDesk', redirect: `/store/${storeId}/worklist` },
		{ role: 'GlobalAdmin', redirect: `/store/${storeId}/worklist` },
		{ role: 'TechnicalAdmin', redirect: `/store/${storeId}/worklist` },
	]

	switch (userRole) {
		case 'Doctor':
			return inStore && !storeId ? STORE_SELECTION_URL : doctorUrl
		case 'GlobalAdmin':
		case 'Technician':
		case 'TechnicalAdmin':
			return worklistOrStoreSelect
		case 'Refractionist':
			return `/refractionist/dashboard`
		default:
			return worklistOrStoreSelect
	}
}

export const redirectToNam = () => {
	const acr =
		'urn%3Aoasis%3Anames%3Atc%3ASAML%3A2.0%3Aac%3Aclasses%3ATelemedicine'

	const appLoginUrl = `${appConfig.login.url}/?response_type=code&client_id=${
		appConfig.login.clientId
	}&redirect_uri=${window.encodeURIComponent(
		appConfig.login.redirectUriEclips,
	)}&scope=profile&acr_values=${acr}`

	window.location.href = appLoginUrl
}

export const handle401Error = <T extends { status: number }>(res: T) => {
	const isAppInsideEclips = !!getEclipsMetadata()

	if (res.status === 401) {
		if (isAppInsideEclips) {
			store.dispatch<any>(authActions.logoutAction(true))
		} else {
			store.dispatch<any>(authActions.setAuthenticationError(true))
		}
		return res
	} else {
		return res
	}
}
