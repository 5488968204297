import { createSelector } from '@reduxjs/toolkit'
import i18n from 'i18next'

import { getUserDashboard } from '../../libs/auth'
import { isNARegion } from '../../libs/region'
import {
	adminRoles,
	inStoreUserRoles,
	userRoleAndPrivileges,
} from '../../libs/users'
import { RootState, UsersState } from '../../model/model'
import { Practice } from '../../model/practice'
import { Role, User, UserPrivilege } from '../../model/users'
import {
	selectInStore,
	selectIsRoomDeviceAvailable,
	selectStoreId,
} from '../app/selectors'
import { selectUsername, selectUserRole } from '../auth/selectors'
import {
	selectAllUserPracticesManager,
	selectPractice,
	selectPractices,
} from '../practices/selectors'

export const selectUserByUsername =
	(username?: string) =>
	(state: RootState): User | undefined =>
		username ? state.users[username] : undefined

const _selectUsers = (state: RootState) => state.users
export const selectUsers = createSelector(_selectUsers, users =>
	Object.values(users),
)

export const selectUser = createSelector<
	[(state: RootState) => UsersState, (state: RootState) => string],
	User | undefined
>(_selectUsers, selectUsername, (users, username) =>
	username ? users[username] : undefined,
)

export const selectUserAvailability = (username: string) =>
	createSelector(selectUserByUsername(username), user => user?.available)

export const selectTeloEnabled = createSelector(selectUser, user => {
	if (!user || user.role !== 'Doctor') {
		return true
	}

	if (!user.licenses) {
		return false
	}

	return user.licenses.some(l => l.grants.teleoptometry === true)
})

export const selectRenewalPrescriptionEnabled = createSelector(
	selectUser,
	user => {
		if (!user || !user.licenses || !isNARegion) {
			return false
		}
		return user.licenses.some(l => l.grants.renewalPrescription === true)
	},
)

export const selectIsDoctor = createSelector(
	selectUserRole,
	userRole => userRole === 'Doctor',
)

export const selectIsLocalTechnician = createSelector(
	selectUserRole,
	userRole => userRole === 'Technician',
)

export const selectIsGlobalAdmin = createSelector(
	selectUserRole,
	userRole => userRole === 'GlobalAdmin',
)

export const selectIsFrontDesk = createSelector(
	selectUserRole,
	userRole => userRole === 'FrontDesk',
)

export const selectIsDoctorAdmin = (username?: string) => (state: RootState) =>
	username ? Boolean(state.users[username]?.doctorAdmin) : false

export const selectIsRefractionist = createSelector(
	selectUserRole,
	userRole => userRole === 'Refractionist',
)

export const selectIsAdmin = createSelector(selectUserRole, userRole =>
	adminRoles.includes(userRole),
)

export const selectPendingExamId = (username?: string) => (state: RootState) =>
	username ? state.users[username]?.pendingExam : undefined

export const selectUserLocation = createSelector(
	selectUser,
	selectIsDoctor,
	selectIsRefractionist,
	(user, isDoctor, isRefractionist) => {
		if (isDoctor || isRefractionist) {
			return user && user.loggedInAsInStore
				? i18n.t('app.store')
				: i18n.t('app.remote')
		}
		return ''
	},
)

export const selectUserStores = createSelector(
	selectUsername,
	_selectUsers,
	(username, users) => users[username]?.stores || [],
)

export const _selectIsInStoreUser = (userRole: Role, inStore: boolean) => {
	const inStoreRoles: Role[] = [
		'TechnicalAdmin',
		'GlobalAdmin',
		'Technician',
		'Refractionist',
		'FrontDesk',
	]
	const hasInStoreRole = inStoreRoles.includes(userRole)
	return hasInStoreRole || inStore
}

export const selectIsInStoreUser = createSelector(
	selectUserRole,
	selectInStore,
	_selectIsInStoreUser,
)

export const selectUserDashboardPath = (state: RootState) => {
	const user = selectUser(state)
	if (!user) {
		return
	}
	const isInStoreUser = selectIsInStoreUser(state)
	const userStores = selectUserStores(state)
	const userRole = selectUserRole(state)
	const teloEnabled = selectTeloEnabled(state)
	const renewalPrescriptionEnabled = selectRenewalPrescriptionEnabled(state)
	const roomDeviceSelected = selectIsRoomDeviceAvailable(state)

	if (userRole === 'GlobalAdmin') {
		return '/admin'
	}

	const isStoreIdNeeded = isInStoreUser || inStoreUserRoles.includes(userRole)
	const storeId = selectStoreId(state)
	const inStoreCount = userStores.length

	if (isStoreIdNeeded && !storeId && inStoreCount === 1) {
		return
	}
	if (isStoreIdNeeded && !storeId && inStoreCount > 1) {
		return '/store-selection'
	}

	if (
		userRole === 'Doctor' &&
		isInStoreUser &&
		roomDeviceSelected === undefined
	) {
		return '/room-device'
	}

	return getUserDashboard(
		userRole,
		storeId,
		teloEnabled,
		renewalPrescriptionEnabled,
	)
}

export const selectCanEditPreTestValues = createSelector(
	selectIsDoctor,
	selectIsRefractionist,
	selectIsLocalTechnician,
	selectInStore,
	(isDoctor, isRefractionist, isLocalTechnician, inStore) =>
		isLocalTechnician || isDoctor || (isRefractionist && inStore),
)

export const selectUserRoleAndPrivileges = createSelector(selectUser, user => {
	return userRoleAndPrivileges(user)
})

export const selectUserPrivilegesByStoreId =
	(storeId: string) => (state: RootState) => {
		const user = selectUser(state)
		const store = user?.stores.find(s => s.store._id === storeId)

		return store?.privileges || []
	}

export const selectUserCanOpenEPrescribing = createSelector(
	selectUsername,
	_selectUsers,
	(username, users) => {
		const user = username ? users[username] : undefined

		if (!user) {
			return false
		}

		return user.enableEPrescribing
	},
)

export const selectUserCanOpenMIPS = createSelector(
	selectUsername,
	_selectUsers,
	(username, users) => {
		const user = username ? users[username] : undefined

		if (!user) {
			return false
		}

		return user.mipsAccess || false
	},
)

export const selectIsRemoteDoctor = createSelector(
	selectIsDoctor,
	selectInStore,
	(isDoctor, isInStore) => isDoctor && !isInStore,
)

export const selectUserByPractice = (practiceId: string) =>
	createSelector(selectPractice(practiceId), selectUsers, (practice, users) => {
		if (!practice) {
			return []
		}

		const practiceStores = practice.stores.flatMap(st => st._id)

		const leUser = users.reduce((ac, current) => {
			if (
				current.stores &&
				current.stores.some(
					userStore =>
						userStore.store && practiceStores.includes(userStore.store._id),
				)
			) {
				return [...ac, current]
			}
			return ac
		}, [] as User[])
		return leUser
	})

export const selectUserManagedPractices = createSelector(selectUser, user => {
	if (!user) {
		return []
	}

	if (!user.practices) {
		return []
	}

	if (user.practices.length === 0) {
		return []
	}

	const practices = user.practices.filter(({ privileges }) =>
		privileges.includes('LegalEntityManager'),
	)

	return practices
})

export const selectUserManagedPracticesCompleteInfo = createSelector(
	selectUserManagedPractices,
	selectPractices,
	(managedPractices, allPractices) => {
		return managedPractices.reduce((ac, p) => {
			const practice = allPractices.find(le => le._id === p.practice)
			return !practice ? ac : [...ac, practice]
		}, [] as Practice[])
	},
)

export const selectIsPracticeManager = createSelector(
	selectUserManagedPractices,
	practices => !!practices.length,
)

export const userCanEditPanel = createSelector(
	selectIsGlobalAdmin,
	selectIsPracticeManager,
	(isGlobaAdmin, isLegalEntityManager): boolean =>
		isGlobaAdmin || isLegalEntityManager,
)

export const userCanEditPractice = (practiceId?: string) =>
	createSelector(
		selectIsGlobalAdmin,
		selectIsPracticeManager,
		selectAllUserPracticesManager,
		(isGlobaAdmin, isLegalEntityManager, userPractices): boolean => {
			if (isGlobaAdmin || !practiceId) {
				return true
			}
			if (isLegalEntityManager) {
				const userPracticeIds = userPractices.map(({ _id }) => _id)
				return userPracticeIds.some(id => practiceId === id)
			}
			return false
		},
	)

export const selectIsRightToBeForgotten = (practiceId: string) =>
	createSelector(selectUser, user => {
		if (!user) {
			return false
		}

		if (!user.practices) {
			return false
		}

		if (user.practices.length === 0) {
			return false
		}

		const isInLEUser = user.practices.find(
			({ practice }) => practice === practiceId,
		)

		if (!isInLEUser) {
			return false
		}

		return isInLEUser.privileges.includes(UserPrivilege.RightToBeForgotten)
	})

export const selectManagerPractices = createSelector(
	selectUser,
	(state: RootState): Practice[] => state.practices || [],
	(user, practices): Pick<Practice, '_id' | 'name' | 'externalIds'>[] => {
		if (!user) {
			return []
		}

		if (!user.practices) {
			return []
		}

		if (user.practices.length === 0) {
			return []
		}

		return user.practices
			.filter(({ privileges }) => privileges.includes('LegalEntityManager'))
			.map(pc => {
				const practice = practices.find(
					practice => practice._id === pc.practice,
				)

				return {
					_id: practice?._id || '',
					name: practice?.name || '',
					externalIds: practice?.externalIds || [],
				}
			})
	},
)

export const selectPracticeManagers = (practiceId: string) =>
	createSelector(selectUsers, users =>
		users.filter(({ practices }) =>
			practices?.some(p => p.practice === practiceId),
		),
	)
