import React from 'react'
// used only in TeloApp
// eslint-disable-next-line telo/no-react-router-import
import { Navigate, useLocation } from 'react-router-dom'

import CheckExamStatusAndPathMatch from './components/CheckExamStatusAndPathMatch'
import { selectAuthToken, selectUserRole } from './features/auth/selectors'
import { selectSelectedStoreId } from './features/stores/selectors'
import {
	selectRenewalPrescriptionEnabled,
	selectTeloEnabled,
	selectUserDashboardPath,
} from './features/users/selectors'
import { useEclipsKeepAlive } from './hooks/useEclipsKeepalive'
import useEclipsMetadata from './hooks/useEclipsMetadata'
import { useExamTimerDoctor, useExamTimerPretest } from './hooks/useExamTimer'
import { useGetNewCropStatusCount, useKeepAliveUser } from './hooks/usePolling'
import { Role } from './model/users'
import { useTeloDispatch, useTeloSelector } from './store'
import { useTokenExpirationNotification } from './hooks/useTokenExpirationNotification'
import { WithTeloNavigation } from './WithTeloNavigation'
import ErrorBoundary from './features/errors/ErrorBoundary'
import UIErrors from './features/errors/UIErrors'
import DialogBox from './features/dialog/DialogBox'
import { isGeminiRegion } from './libs/region'

const authorizesRolesForRoute: { [route: string]: Role[] } = {
	'/exam-attribution/:examId': [
		'TechnicalAdmin',
		'GlobalAdmin',
		'Technician',
		'Doctor',
	],
	'/store/:storeId/pretest/:examId': [
		'TechnicalAdmin',
		'GlobalAdmin',
		'Technician',
		'Doctor',
	],
	'/store/:storeId/check-in/:appointmentId': [
		'TechnicalAdmin',
		'GlobalAdmin',
		'Technician',
		'Doctor',
	],
	'/store/:storeId/admin-stages': ['TechnicalAdmin', 'GlobalAdmin'],
	'/store/:storeId/worklist': [
		'TechnicalAdmin',
		'GlobalAdmin',
		'Technician',
		'FrontDesk',
	],
	'/refractionist/dashboard': ['Refractionist'],
	'/refractionist/exam/:examId': ['Refractionist'],
	'/doctor/worklist': ['Doctor'],
	'/doctor/search': ['Doctor'],
	'/doctor/exam/:examId': ['Doctor'],
	'/admin': [
		'TechnicalAdmin',
		'GlobalAdmin',
		'Doctor',
		'Technician',
		'FrontDesk',
	],
	'/admin/users': ['TechnicalAdmin', 'GlobalAdmin', 'FrontDesk'],
	'/admin/new-user': ['TechnicalAdmin', 'Doctor', 'GlobalAdmin', 'FrontDesk'],
	'/admin/new-store': ['TechnicalAdmin', 'GlobalAdmin'],
	'/admin/new-plan': ['TechnicalAdmin', 'GlobalAdmin'],
	'/admin/plan/:planId': [
		'TechnicalAdmin',
		'GlobalAdmin',
		'Doctor',
		'Technician',
	],
	'/admin/stores': ['TechnicalAdmin', 'GlobalAdmin'],
	'/admin/user/:username': ['TechnicalAdmin', 'GlobalAdmin'],
	'/admin/store/:storeId': [
		'TechnicalAdmin',
		'GlobalAdmin',
		'Doctor',
		'Technician',
	],
	'/exams/:examId/:step': ['Doctor', 'Technician', 'Refractionist'],
}

type AuthenticatedRouteProps = { children?: React.ReactNode }

const AuthenticatedRoute = ({ children }: AuthenticatedRouteProps) => {
	const dispatch = useTeloDispatch()
	const { pathname } = useLocation()
	const loginToken = useTeloSelector(selectAuthToken)
	const userRole = useTeloSelector(selectUserRole)
	const dashboardPath = useTeloSelector(selectUserDashboardPath)
	const isRPEnabled = useTeloSelector(selectRenewalPrescriptionEnabled)
	const isTeloEnabled = useTeloSelector(selectTeloEnabled)
	const selectedStoreId = useTeloSelector(selectSelectedStoreId)

	const eclipsMetadata = useEclipsMetadata()
	const eclipsMode = eclipsMetadata !== undefined
	const skipRenewToken = eclipsMode === true || isGeminiRegion === true

	useKeepAliveUser()

	useEclipsKeepAlive(eclipsMode)
	useExamTimerDoctor(loginToken)
	useExamTimerPretest(loginToken)
	useTokenExpirationNotification(loginToken, skipRenewToken, dispatch)
	useGetNewCropStatusCount(selectedStoreId)

	if (!loginToken) {
		return <Navigate to="/login" />
	}

	if (!isRPEnabled && !isTeloEnabled) {
		return <Navigate to="/grants" />
	}
	const paths = pathname ? [pathname] : []

	const cannotOpenTelo = !isTeloEnabled && paths.includes('/doctor/worklist')
	const cannotOpenRenewal =
		!isRPEnabled && paths.includes('/doctor/renewal/dashboard')

	const roleIsAuthorized = paths.every(
		path =>
			!authorizesRolesForRoute[path] ||
			!authorizesRolesForRoute[path].length ||
			authorizesRolesForRoute[path].some(role => userRole === role),
	)
	if (
		(cannotOpenTelo || cannotOpenRenewal || !roleIsAuthorized) &&
		dashboardPath
	) {
		return <Navigate to={dashboardPath} />
	}

	return (
		<WithTeloNavigation>
			<ErrorBoundary>
				<UIErrors>
					<CheckExamStatusAndPathMatch paths={[...paths]} />
					{children}
					<DialogBox />
				</UIErrors>
			</ErrorBoundary>
		</WithTeloNavigation>
	)
}

export default AuthenticatedRoute
