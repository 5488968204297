import React, {
	DetailedHTMLProps,
	HTMLAttributes,
	MouseEvent,
	useCallback,
} from 'react'
import { ChildrenProps } from '../utils/utilityTypes'
import { TeloDestination, useTeloRouter } from './teloRouter'

type SpanProps = DetailedHTMLProps<
	HTMLAttributes<HTMLSpanElement>,
	HTMLSpanElement
>

type TeloLinkProps = SpanProps &
	ChildrenProps & {
		to: TeloDestination
	}

export const TeloLink = ({
	to,
	children,
	onClick,
	...htmlProps
}: TeloLinkProps) => {
	const { navigate: teloNavigate } = useTeloRouter()
	const handleClick = useCallback(
		(e: MouseEvent<HTMLSpanElement>) => {
			teloNavigate(to)
			onClick?.(e)
		},
		[onClick, teloNavigate, to],
	)
	return (
		<span {...htmlProps} onClick={handleClick}>
			{children}
		</span>
	)
}
