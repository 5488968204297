import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Id, PrescriptionsState } from '../../model/model'
import { Prescription } from '../../model/prescription'

const initialState: PrescriptionsState = {
	list: {},
	inShare: undefined,
	isDirty: false,
	hasErrors: true,
}

export const slice = createSlice({
	name: 'prescriptions',
	initialState,
	reducers: {
		_loadPrescription: (state, { payload }: PayloadAction<Prescription>) => {
			const oldPrescription = state.list[payload._id]
			state.list[payload._id] = {
				...payload,
				blobDocumentUrl: oldPrescription?.blobDocumentUrl,
			}
		},
		setInShare: (state, { payload }: PayloadAction<string | undefined>) => {
			state.inShare = payload
		},
		_setIsDirty: (state, { payload }: PayloadAction<boolean>) => {
			state.isDirty = payload
		},
		_setHasErrors: (state, { payload }: PayloadAction<boolean>) => {
			state.hasErrors = payload
		},
		_savePdfDocument: (
			state,
			{ payload }: PayloadAction<{ prescriptionId: Id; doc: string }>,
		) => {
			const { prescriptionId, doc } = payload
			state.list[prescriptionId] = {
				...state.list[prescriptionId],
				blobDocumentUrl: doc,
			}
		},
	},
})

export default slice.reducer
