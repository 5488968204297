import { isSameDay, isToday } from 'date-fns'
import { toDate, utcToZonedTime } from 'date-fns-tz'
import { memoize, uniqBy } from 'lodash'
import {
	compose,
	defaultTo,
	filter,
	isUndefined,
	map,
	negate,
	prop,
	uniq,
} from 'lodash/fp'

import { createSelector } from '@reduxjs/toolkit'

import {
	completedExamStatus,
	convertExamStatusToNumber,
	isExam,
	isExamCallable,
	isExamOpenForDoctor,
	shouldImportOldExamData,
} from '../../libs/exams'
import { isTodayZoned } from '../../libs/time'
import { AppointmentType } from '../../model/appointment'
import {
	Exam,
	ExamStatus,
	EyeHealthDocument,
	ReferralDocument,
	StrippedExam,
} from '../../model/exam'
import {
	ExamsState,
	ExamsWithCurrentDisplayedExam,
	Id,
	RootState,
} from '../../model/model'
import { DocumentToPrint } from '../../model/patient'
import { Prescription } from '../../model/prescription'
import { CONTAINER_NAME } from '../../model/types'
import { selectIsRoomDeviceAvailable } from '../app/selectors'
import { selectUsername } from '../auth/selectors'
import { selectPrivacyPolicyDocuments } from '../stores/selectors'
import { examsDoctorInStore } from './../../libs/exams'
import { selectUserLocation } from '../users/selectors'
import {
	selectCurrentPractice,
	selectIsUserAssignedToPractice,
} from '../practices/selectors'

export const selectExamsRaw = (state: RootState): ExamsState => state.exams

export const selectExams = createSelector<
	[(state: RootState) => ExamsState],
	ExamsWithCurrentDisplayedExam
>(selectExamsRaw, examsById => uniqBy(Object.values(examsById), '_id'))

export const selectRefractionistExamsWithStatus =
	({
		username,
		from,
		to,
	}: {
		username: string
		from: ExamStatus
		to?: ExamStatus
	}) =>
	(state: RootState) => {
		const examsFiltered = Object.values(state.exams).filter(exam => {
			const statusN = convertExamStatusToNumber(exam.status)

			return (
				isExam(exam) &&
				(!exam.refractionist || exam.refractionist === username) &&
				statusN >= convertExamStatusToNumber(from) &&
				(to ? statusN < convertExamStatusToNumber(to) : true)
			)
		})

		return examsFiltered
	}

const addDateTimezonedToExams = memoize((exams: (Exam | StrippedExam)[]) =>
	exams.map(exam => {
		const timeZone = exam.store.timezone
		const dateTimezoned = utcToZonedTime(
			exam.checkedInAt || exam.externalAppointment.date,
			timeZone,
		)

		return {
			...exam,
			dateTimezoned,
		}
	}),
)

export const selectExamByAppointmentId =
	(appointmentId: Id, appointmentDate: string, appointmentTimezone: string) =>
	(state: RootState) => {
		const exams = selectExams(state)
		const examWithCreatedAtTimeZoned = addDateTimezonedToExams(exams)
		const exam = examWithCreatedAtTimeZoned.find(exam => {
			const exists =
				exam.appointmentId === appointmentId &&
				isSameDay(
					exam.dateTimezoned,
					utcToZonedTime(toDate(appointmentDate), appointmentTimezone),
				)
			return exists
		})
		return exam
	}

export const selectExamsByInternalPatientId =
	(internalPatientId: Id) => (state: RootState) => {
		const exams = selectExams(state)
		const selectedExams = exams.filter(
			exam => exam.internalPatient._id === internalPatientId,
		)
		return selectedExams
	}

export const selectExam =
	(examId: Id) =>
	(state: RootState): Exam | undefined => {
		let exam =
			state.exams[examId] ||
			(state.exams.currentDisplayedExam?._id === examId &&
				state.exams.currentDisplayedExam)

		return exam && isExam(exam) ? exam : undefined
	}

export const selectExamStatus =
	(examId: Id) =>
	(state: RootState): string | undefined => {
		const exam = state.exams[examId]

		return exam?.status
	}

export const selectExamHistory = (examId?: Id) => (state: RootState) => {
	const exam = examId && selectExam(examId)(state)
	return exam && exam.history
}

export const selectDoctorDoneExam = (doctorUsername: string) =>
	createSelector(selectExams, exams =>
		exams.filter(
			exam =>
				isExam(exam) &&
				exam.doctor === doctorUsername &&
				convertExamStatusToNumber(exam.status) >=
					convertExamStatusToNumber('DoctorEnded'),
		),
	)

export const selectPatientExams = createSelector<
	[
		(state: RootState) => ExamsWithCurrentDisplayedExam,
		(_: RootState, patientId?: string) => string | undefined,
	],
	(Exam | StrippedExam)[]
>(
	selectExams,
	(_, patientId) => patientId,
	(exams, patientId) => {
		const filteredExams = exams.filter(
			exam => patientId && exam.internalPatient?._id === patientId,
		)

		return filteredExams
		// * We use ? for backward comatibility with old exams that don't have the internalPatient
	},
)

export const selectExamTypesByPatientId = createSelector<
	[
		(state: RootState) => (Exam | StrippedExam)[],
		(_: RootState, patientId?: string) => string | undefined,
	],
	AppointmentType[] | []
>(
	selectPatientExams,
	(_, patientId) => patientId,
	compose<
		[(Exam | StrippedExam)[]],
		(AppointmentType | undefined)[],
		AppointmentType[] | [],
		AppointmentType[] | []
	>(
		uniq,
		filter<AppointmentType>(negate(isUndefined)),
		map<Exam | StrippedExam, AppointmentType | undefined>(
			({ examType }) => examType,
		),
	),
)

export const selectPatientExamIds = (patientId: string) =>
	createSelector(
		(state: RootState): ExamsState => state.exams,
		exams =>
			// We use ? for backward comatibility with old exams that don't have the internalPatient
			{
				const patientExams = [...Object.values(exams)]
					.filter(exam => exam.internalPatient?._id === patientId)
					.filter(
						({ status, externalAppointment, store }) =>
							isTodayZoned(externalAppointment.date, store.timezone) ||
							convertExamStatusToNumber(status) >=
								convertExamStatusToNumber('DoctorEnded'),
					)
					.map(({ _id }) => _id)
					.filter((id, index, ids) => ids.indexOf(id) === index)
				return patientExams
			},
	)

export const selectTodayExams = createSelector(selectExams, exams =>
	exams.filter(({ updatedAt }) => isToday(new Date(updatedAt))),
)

export const selectExamsByDate = (targetDate: Date, inStore = false) =>
	createSelector(selectExams, exams => {
		const filteredExams = exams
			.filter(({ checkedInAt, externalAppointment }) =>
				isSameDay(
					new Date(checkedInAt || externalAppointment.date),
					new Date(targetDate),
				),
			)
			.filter(exam =>
				inStore ? examsDoctorInStore.includes(exam.status) : exam,
			)

		return uniqBy(filteredExams, '_id')
	})

export const selectEyeHealthDocByExamId = createSelector<
	[(state: RootState) => ExamsState, (_: RootState, examId: string) => string],
	EyeHealthDocument | undefined
>(
	selectExamsRaw,
	(_, examId) => examId,
	(exams, examId) => {
		const exam = exams[examId]

		if (isExam(exam)) {
			return exam.eyeHealth?.document
		}
	},
)

export const selectReferralDocByExamId = createSelector<
	[(state: RootState) => ExamsState, (_: RootState, examId: string) => string],
	ReferralDocument | undefined
>(
	selectExamsRaw,
	(_, examId) => examId,
	(exams, examId) => {
		const exam = exams[examId]

		if (isExam(exam)) {
			return exam.referralForm?.document
		}
	},
)

export const selectDocumentsToPrint = (
	examId: Id,
	t: (key: string) => string,
	surname?: string,
	prescription?: Prescription,
) =>
	createSelector(selectExams, exams => {
		const filteredExams = exams.filter(({ _id }) => _id === examId)
		const exam = filteredExams[filteredExams.length - 1]

		if (!surname || !exam || !isExam(exam) || !exam.privacyPolicyDocuments) {
			return []
		}

		const clTrialToPrint =
			exam.contactLensesTrial !== undefined
				? exam.contactLensesTrial.reduce((acc, current, index) => {
						if (!current.trialType || current.status !== 'TRIAL') {
							return acc
						}

						const doc: DocumentToPrint = {
							name: `${t('doctor.trial')} #${index + 1}`,
							printName: `${t('doctor.trial')} #${index + 1}`,
							path: 'clTrial',
							alternativeIndex: index,
							type: 'cl',
							date: new Date(
								current.timestamp ||
									exam.checkedInAt ||
									exam.externalAppointment.date,
							).toISOString(),
							print: true,
							container: 'ppd',
						}
						return [...acc, doc]
				  }, [] as DocumentToPrint[])
				: []

		const prescriptionDate = exam.history.find(({ currentStatus }) =>
			[
				'DoctorEndedWithLensTrial',
				'DoctorEnded',
				'LensTrialQuit',
				'Interrupted',
			].includes(currentStatus),
		)?.statusUpdatedAt

		const privacyPolicyDocuments: DocumentToPrint[] =
			exam.privacyPolicyDocuments.map(({ ref, signedDate, name }) => {
				const safeName = name || 'document'
				return {
					name: safeName,
					printName: `${safeName}_${surname}`,
					path: ref,
					type: 'file',
					date: signedDate.toString(),
					print: false,
					container: 'ppd',
				}
			})
		const referralDoc: DocumentToPrint[] =
			exam.referralForm?.document &&
			exam.referralForm.document.signed &&
			exam.referralForm.document.sent
				? [
						{
							name: t('exam.referralDoc'),
							printName: `${t('exam.referralDoc')}_${surname}`,
							path: exam.referralForm.document.fileName,
							type: 'file',
							date: new Date(
								exam.referralForm.document.timestamp,
							).toISOString(),
							print: false,
							container: 'ppd',
						},
				  ]
				: []

		const prescriptionDoc: DocumentToPrint[] =
			prescriptionDate && prescription && prescription.sent
				? [
						{
							name: t('patient.prescription'),
							printName: `${t('patient.prescription')}_${surname}`,
							path: prescription.filename,
							type: 'prescription',
							date: prescriptionDate.toString(),
							print: false,
							container: 'ppd',
						},
				  ]
				: []

		const eyeHealth: DocumentToPrint[] =
			exam.eyeHealth?.document && prescription && prescription.sent
				? [
						{
							name: t('exam.eyeHealthDoc'),
							printName: `${t('exam.eyeHealthDoc')}_${surname}`,
							path: exam.eyeHealth?.document.fileName,
							type: 'file',
							date: new Date(exam.eyeHealth?.document.timestamp).toISOString(),
							print: false,
							container: 'ppd',
						},
				  ]
				: []

		// order summary document can be see only when exam is Ended
		const ordersDocuments: DocumentToPrint[] =
			// examEndedStatus.includes(exam.status) &&
			exam.orders && exam.orders.length > 0
				? exam.orders
						.filter(
							order =>
								order.status !== 'draft' &&
								order.document &&
								order.document.fileName &&
								order.document.timestamp,
						)
						.map((order, index) => {
							const name = `${t(`exam.orders.${order.orderType}FileName`)}${
								order.reasonCpt ? ` - ${order.reasonCpt}` : ''
							}`
							return {
								name: name,
								printName: `${name} - ${index}`,
								path: order.document!.fileName,
								type: 'file',
								date: new Date(order.document!.timestamp).toISOString(),
								print: false,
								container: 'orders',
								status: order.status,
							}
						})
				: []

		const surgeriesDocuments: DocumentToPrint[] =
			exam.surgeries &&
			exam.surgeries.filter(({ document }) => !!document).length > 0
				? exam.surgeries.map((surgery, index) => {
						const name = `${t(`exam.surgeryTab.fileName`)}${
							surgery.procedureCTPs[0]
								? ` - ${surgery.procedureCTPs[0].value} ${surgery.procedureCTPs[0].label}`
								: ''
						}`
						return {
							name: name,
							printName: `${name} - ${index}`,
							path: surgery.document!.fileName,
							type: 'file',
							date: new Date(surgery.document!.timestamp).toISOString(),
							print: false,
							container: surgery.document!.container as CONTAINER_NAME,
							status: surgery.status,
						}
				  })
				: []

		const charts = exam.charts !== undefined ? exam.charts : []

		// https://stackoverflow.com/a/57917728/853887
		const chartDocMapped: DocumentToPrint[] = charts.map((c, index) => ({
			name: `${t('patient.examChart')}_${index}`,
			printName: `${t('patient.examChart')}_${surname}_${index}`,
			path: c.fileName,
			type: 'file',
			date: new Date(c.timestamp).toISOString(),
			print: false,
			container: c.container as CONTAINER_NAME,
			timestamp: c.timestamp,
		}))

		const chartDoc = [...chartDocMapped].sort((c1, c2) => {
			if (c2.timestamp! > c1.timestamp!) {
				return 1
			}
			return -1
		})

		const chartRunTime: DocumentToPrint[] = exam.chartAvailable
			? [
					{
						name: `${t('patient.examChart')}`,
						printName: `${t('patient.examChart')}_${surname}`,
						path: 'examChart',
						type: 'api',
						date: new Date(exam.updatedAt).toISOString(),
						print: false,
						container: 'charts',
						timestamp: new Date(exam.updatedAt).getTime(),
					},
			  ]
			: []

		const additionalPrescriptionsRaw =
			exam.additionalPrescriptions !== undefined
				? exam.additionalPrescriptions
				: []

		const additionalPrescriptions: DocumentToPrint[] =
			additionalPrescriptionsRaw.map((c, i) => ({
				name: `${t('patient.newJerseyPrescription')} #${i + 1}`,
				printName: `NJRX_${i + 1}_${surname}`,
				path: c.fileName,
				type: 'file',
				date: new Date(c.timestamp).toISOString(),
				print: false,
				container: 'ppd',
			}))

		const medicalReport: DocumentToPrint[] = exam.medicalReports
			? exam.medicalReports.map(mr => ({
					name: `${t('exam.medicalReport')} - ${mr.description}`,
					printName: `${t('exam.medicalReport')} - ${mr.description}`,
					path: mr.fileName || '',
					type: 'file',
					date: new Date(mr.timestamp || Date.now()).toISOString(),
					print: false,
					container: 'medicalreports',
			  }))
			: []

		const myopiaReport: DocumentToPrint[] = exam.myopiaCare?.fileName
			? [
					{
						name: t('exam.myopiaReport'),
						printName: `${t('exam.myopiaReport')}_${surname}`,
						path: exam.myopiaCare.fileName,
						type: 'file',
						date: new Date(exam.myopiaCare.timestamp!).toISOString(),
						print: false,
						container: 'myopia',
					},
			  ]
			: []

		const documentsToPrint = privacyPolicyDocuments
			.concat(referralDoc)
			.concat(prescriptionDoc)
			.concat(eyeHealth)
			.concat(chartRunTime)
			.concat(chartDoc)
			.concat(additionalPrescriptions)
			.concat(ordersDocuments)
			.concat(medicalReport)
			.concat(myopiaReport)
			.concat(clTrialToPrint)
			.concat(surgeriesDocuments)

		const documentsToPrintWithIndex: DocumentToPrint[] = documentsToPrint.map(
			(doc, index) => ({ ...doc, index }),
		)

		return documentsToPrintWithIndex
	})

export const selectExamSupportVideoCall =
	(examId: string) => (state: RootState) => {
		const exam = selectExam(examId)(state)

		if (!exam) {
			return false
		}

		const isCallable = isExamCallable(exam)

		return isCallable
	}

export const selectExamContactLensesId =
	(examId: string) => (state: RootState) => {
		const exam = selectExam(examId)(state)
		if (!exam) {
			return []
		}
		if (!exam.contactLensesTrial) {
			return []
		}
		const contactLensesId = exam.contactLensesTrial?.flatMap(c => [
			c.catalogueId.OD,
			c.catalogueId.OS,
		])
		const uniq = [...new Set(contactLensesId)]
		return uniq
	}

export const selectExamAttachments = (examId: string) => (state: RootState) => {
	const exam = selectExam(examId)(state)

	if (!exam) {
		return []
	}
	return exam.attachments || []
}

export const selectExamLinks = (examId: string) => (state: RootState) => {
	const exam = selectExam(examId)(state)

	if (!exam) {
		return []
	}
	return exam.links || []
}

export const selectIsThereAnOpenExamAssignedToDoctor = (state: RootState) => {
	const username = selectUsername(state)
	const exams = selectExams(state)
	const isThereAnOpenExamAssignedToDoctor = exams
		.filter(isExam)
		.filter(
			({ historyDoctor }) =>
				historyDoctor &&
				historyDoctor[historyDoctor.length - 1]?.doctor === username,
		)
		.some(isExamOpenForDoctor)

	return isThereAnOpenExamAssignedToDoctor
}

export const selectAllExamsForPatientOfExam =
	(examId: string) => (state: RootState) => {
		const exam = selectExam(examId)(state)
		const patientId = exam?.internalPatient._id
		const patientExams = selectPatientExams(state, patientId)

		return patientExams
	}

export const selectOpenExamForPatientOfExam =
	(examId: string) => (state: RootState) => {
		const patientExams = selectAllExamsForPatientOfExam(examId)(state)
		const patientOpenExam = patientExams?.find(
			({ status }) => !completedExamStatus.includes(status),
		)

		return patientOpenExam
	}

export const selectLensTrialExamsForPatientOfExam =
	(exam?: Exam) => (state: RootState) => {
		if (!exam || !shouldImportOldExamData(exam.externalAppointment.appType)) {
			return
		}

		const exams = selectExamsByInternalPatientId(exam.internalPatient._id)(
			state,
		)

		const sortedExams = exams
			.filter(
				exam => exam.contactLensesTrial && exam.contactLensesTrial.length > 0,
			)
			.sort(
				(a, b) =>
					Date.parse(b.createdAt.toString()) -
					Date.parse(a.createdAt.toString()),
			)

		if (sortedExams.length === 0) {
			return undefined
		} else {
			return sortedExams[0]
		}
	}

export const selectExamHasCharts = (examId: string) =>
	createSelector(selectExams, exams => {
		const exam = exams.find(exam => exam._id === examId)!

		if ('isStripped' in exam) {
			return exam.hasCharts
		}

		return (exam.charts && exam.charts.length > 0) || exam.chartAvailable
	})

export const selectAssessmentPlanByExamId = (examId: string) =>
	createSelector(selectExam(examId), exam => exam?.assessmentPlan)

export const selectIsRemoteExam = (examId: string) =>
	createSelector(selectExam(examId), exam => exam?.mode === 'remote')

export const selectCurrentlyDisplayedExam = createSelector<
	[(state: RootState) => ExamsState],
	Exam | StrippedExam | undefined
>(
	selectExamsRaw,
	prop<ExamsState, 'currentDisplayedExam'>('currentDisplayedExam'),
)

export const selectCurrentlyDisplayedExamId = createSelector<
	[(state: RootState) => Exam | StrippedExam | undefined],
	string
>(
	selectCurrentlyDisplayedExam,
	compose<[Exam | StrippedExam | undefined], string | undefined, string>(
		defaultTo(''),
		prop<Exam | StrippedExam, '_id'>('_id'),
	),
)

export const selectShouldRenderRoomSelect = (examId: string) =>
	createSelector(
		selectExam(examId),
		selectIsRoomDeviceAvailable,
		(exam, isRoomDeviceAvailable) => {
			if (
				!exam ||
				!['DoctorStarted', 'DoctorSelected'].includes(exam?.status)
			) {
				return false
			}
			return exam?.mode === 'remote' || isRoomDeviceAvailable
		},
	)

export const selectIsExamLockedByUser = (examId: string) =>
	createSelector(
		selectExam(examId),
		selectUsername,
		(exam, username) => exam?.lockedBy === username,
	)

export const selectExamLockedByHistory = (examId: string) =>
	createSelector(selectExam(examId), exam => exam?.lockedByHistory)

export const selectOldPrivacyPolicyDocsSigned = (patientId: string) =>
	createSelector(
		(state: RootState) => selectPatientExams(state, patientId),
		selectPrivacyPolicyDocuments,
		(patientExams, storePPD) => {
			const oldExamsPPD = patientExams
				?.slice()
				.filter(({ status }) => status !== 'Planned')
				.sort(
					(a, b) =>
						new Date(b.checkedInAt || b.externalAppointment.date).getTime() -
						new Date(a.checkedInAt || a.externalAppointment.date).getTime(),
				)
				.flatMap(e => e.privacyPolicyDocuments || [])
			if (storePPD && oldExamsPPD) {
				const signedPPD = oldExamsPPD.filter(d => {
					const signedDoc = storePPD.find(s => s._id === d.storeDocumentId)
					return signedDoc
				})
				return (signedPPD || []).filter(
					(value, index, self) =>
						self.findIndex(v => v.storeDocumentId === value.storeDocumentId) ===
						index,
				)
			}

			return []
		},
	)

export const examFlowTemplatesQuery = (examId: string) =>
	createSelector(
		selectUserLocation,
		selectExam(examId),
		selectCurrentPractice,
		(userLocation, activeExam, legalEntity) => {
			const isUserRemote = userLocation?.toLowerCase() === 'remote'

			const filter = isUserRemote
				? {
						remote: true,
				  }
				: {
						appointmentTypeCode: activeExam?.examType,
						legalEntityId: legalEntity?._id,
				  }

			return {
				filter,
				skip: !(activeExam || userLocation),
			}
		},
	)
