import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import i18n from 'i18next'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { NOTISTAK_ASSIGN_QUEUE_KEY } from '../../components/QueueNotifications/constant'
import QueueNotificationsBanner from '../../components/QueueNotifications/queue.banner'
import QueueNotificationsDialog from '../../components/QueueNotifications/queue.dialog'
import { useNotificationErrors } from '../../hooks/useNotificationErrors'
import useNotification from '../../hooks/useNotifications'
import { formatName } from '../../libs/localization'
import { useTeloDispatch, useTeloSelector } from '../../store'
import Alert from '../../styleguide/Alert'
import IconButton from '../../styleguide/buttons/IconButton'
import CloseIcon from '../../styleguide/icons/CloseIcon'
import Snackbar from '../../styleguide/Snackbar'
import appActions from '../app/actions'
import { selectOnline } from '../app/selectors'
import errorsActions from '../errors/actions'
import { selectMissingAuthErrors } from '../errors/selectors'
import queueActions from '../queue/actions'
import {
	selectNoDoctorAvailableModalInfo,
	selectStartedExams,
	selectTimedoutExams,
} from '../queue/selectors'

import notificationsActions from './actions'
import { Notification } from './Notification'
import { selectNotification, selectNotifications } from './selectors'

const useNavigatorOnLine = () => {
	const dispatch = useTeloDispatch()

	useEffect(() => {
		const setOnline = () => dispatch(appActions.setOnline(true))
		const setOffline = () => dispatch(appActions.setOnline(false))

		window.addEventListener('online', setOnline)
		window.addEventListener('offline', setOffline)

		return () => {
			window.removeEventListener('online', setOnline)
			window.removeEventListener('offline', setOffline)
		}
	}, [dispatch])
}

const NotificationArea: FC = () => {
	const { t } = useTranslation()

	const inputRef = useRef<HTMLDivElement>(null)

	const notifications = useTeloSelector(selectNotifications)
	const missingAuthErrors = useTeloSelector(selectMissingAuthErrors)
	const online = useTeloSelector(selectOnline)

	const offlineNotification = useTeloSelector(
		selectNotification('offline-notification'),
	)
	const dispatch = useTeloDispatch()
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	useNotification()

	useNotificationErrors()

	useNavigatorOnLine()

	const [
		isODUnavailabilityStatusDialogOpen,
		setIsODUnavailabilityStatusDialogOpen,
	] = useState<boolean>(false)

	const noDoctorAvailableModalInfo = useTeloSelector(
		selectNoDoctorAvailableModalInfo,
	)

	const callAssignToDoctorNotification = useCallback(
		(examId: string, patientName: string, doctorName: string) => {
			enqueueSnackbar(NOTISTAK_ASSIGN_QUEUE_KEY, {
				preventDuplicate: true,
				anchorOrigin: { horizontal: 'right', vertical: 'top' },
				autoHideDuration: 10000,
				onClose: () => {
					dispatch(queueActions.removeExamAssigned({ examId }))
				},
				content: snackbarId => {
					return (
						<QueueNotificationsBanner
							message={i18n.t('queue.patientAssignToDoctorMessage', {
								patientName,
								doctorName,
							})}
							onClose={() => {
								dispatch(queueActions.removeExamAssigned({ examId }))
								closeSnackbar(snackbarId)
							}}
						/>
					)
				},
			})
		},
		[closeSnackbar, enqueueSnackbar, dispatch],
	)

	const startedExams = useTeloSelector(selectStartedExams)

	useEffect(() => {
		if (offlineNotification) {
			if (online) {
				return dispatch(
					notificationsActions.removeNotification('offline-notification'),
				)
			}

			return dispatch(
				notificationsActions.addNotification({
					id: 'offline-notification',
					type: 'error',
					message: 'errors.connection',
					autoClose: false,
					messageIsLabelKey: true,
				}),
			)
		}
	}, [dispatch, online, offlineNotification])

	useEffect(() => {
		// Try to prevent the issue: play() failed because the user didn't interact with the document first
		// Simulate a click on a empty div
		if (inputRef.current !== null) {
			inputRef.current.click()
		}
	}, [])

	const timedOutExams = useTeloSelector(selectTimedoutExams)

	return (
		<>
			<div ref={inputRef} onClick={() => {}} />
			{notifications.map(notification => (
				<Notification
					key={notification._id}
					onClose={() =>
						dispatch(notificationsActions.removeNotification(notification._id))
					}
					notification={notification}
				/>
			))}
			{missingAuthErrors.map(({ route, id }) => (
				<Snackbar
					key={id}
					open={true}
					action={
						<IconButton
							size="small"
							aria-label="close"
							color="inherit"
							onClick={() => dispatch(errorsActions.removeMissingAuthError(id))}
						>
							<CloseIcon />
						</IconButton>
					}
				>
					<Alert severity="error">{`${t(
						'errors.missingAuthError',
					)} ${route}`}</Alert>
				</Snackbar>
			))}
			<QueueNotificationsDialog
				examId={noDoctorAvailableModalInfo.examId}
				title={t('queue.noDoctorsAvailable')}
				message={i18n.t('queue.noDoctorsAvailableMessage', {
					patientName: formatName(noDoctorAvailableModalInfo.patient),
				})}
				open={noDoctorAvailableModalInfo.show}
				playSound={true}
				onClose={() => {
					dispatch(
						queueActions.setNoDoctorAvailableModalInfo({
							show: false,
							examId: '',
							patient: {
								name: '',
								surname: '',
							},
						}),
					)
				}}
			/>
			<QueueNotificationsDialog
				examId=""
				title={t('queue.allDoctorsUnavailable')}
				message={i18n.t('queue.allDoctorsUnavailableMessage', {
					patientName: 'Some Patient',
				})}
				open={isODUnavailabilityStatusDialogOpen}
				onClose={() => {
					setIsODUnavailabilityStatusDialogOpen(false)
				}}
			/>
			<QueueNotificationsDialog
				examId={timedOutExams[0]?.examId || ''}
				title={t('queue.noDoctorsAvailable')}
				message={i18n.t('queue.doctorsfailedToRespondMessage', {
					patientName: timedOutExams[0]?.patientName || '',
					doctorNameList: timedOutExams[0]?.doctors.join(', ') || [],
				})}
				open={!!timedOutExams.length}
				onClose={() => {
					dispatch(queueActions.removeExamTimedOut(timedOutExams[0]))
				}}
			/>
		</>
	)
}

export default NotificationArea
