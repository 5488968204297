import { createApi } from '@reduxjs/toolkit/dist/query/react'
import config from '../config'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import {
	ExamTemplateDiff,
	ExamTemplateEditable,
	ExamTemplateWithId,
} from '../model/exam'
import { Optional } from '../utils/utilityTypes'

const APPOINTMENT_TYPES = 'appointment-types' as const
const TAG = 'exam-templates' as const
const ALL_TEMPLATES_ID = 'ALL_TEMPLATES' as const
const BASE_URL = `/exam-templates`

type QueryArgs = Optional<{
	appointmentTypeCode?: string
	legalEntityId?: string
	remote?: boolean
	filterByUsername?: boolean
}>

export const examTemplateApi = createApi({
	reducerPath: `examTemplateApi`,
	tagTypes: [TAG, APPOINTMENT_TYPES],
	baseQuery: staggeredBaseQueryWithBailOut(config.apiUrlV3),
	endpoints: builder => ({
		getTemplatesBy: builder.query<ExamTemplateWithId[], QueryArgs>({
			query: args => {
				const appointmentTypeCode = args?.appointmentTypeCode
				const legalEntityId = args?.legalEntityId
				const remote = args?.remote
				const filterByUsername = args?.filterByUsername
				const anyArg =
					remote != null || !!legalEntityId || !!appointmentTypeCode
				const qParams: Record<string, string | string> = {}
				if (appointmentTypeCode) {
					qParams['appointmentTypeCode'] = appointmentTypeCode
				}
				if (legalEntityId) {
					qParams['legalEntityId'] = legalEntityId
				}
				if (filterByUsername) {
					qParams['filterByUsername'] = `${!!filterByUsername}`
				}
				if (remote != null) {
					qParams['remote'] = `${!!remote}`
				}
				const query = new URLSearchParams(qParams)
				return {
					url: anyArg ? `${BASE_URL}?${query.toString()}` : `${BASE_URL}`,
					method: 'GET',
				}
			},
			providesTags: tagAll,
		}),
		doesTemplateNameExist: builder.query<boolean, string>({
			query: templateName => {
				if (!templateName) {
					throw new Error('Cannot search an empty template name')
				}
				return {
					url: `${BASE_URL}/exist?${new URLSearchParams({
						templateName,
					}).toString()}`,
					method: 'GET',
				}
			},
		}),
		addExamTemplate: builder.mutation<void, { template: ExamTemplateEditable }>(
			{
				query: args => ({
					url: BASE_URL,
					body: args,
					method: 'POST',
				}),
				invalidatesTags: invalidateAllTags(),
			},
		),
		deleteExamTemplates: builder.mutation<void, { ids: ReadonlyArray<string> }>(
			{
				query: args => ({
					url: BASE_URL,
					body: args,
					method: 'DELETE',
				}),
				invalidatesTags: invalidateAllTags(),
			},
		),
		toggleIsDefault: builder.mutation<
			ExamTemplateWithId,
			{
				id: string
				newIsDefault: boolean
				args: QueryArgs
			}
		>({
			query: ({ id, newIsDefault }) => {
				return {
					url: `${BASE_URL}/${id}/is-default`,
					method: 'PUT',
					body: { isDefault: newIsDefault },
				}
			},
			onQueryStarted: async (
				{ id, newIsDefault, args },
				{ dispatch, queryFulfilled },
			) => {
				const patchResult = dispatch(
					optimisticUpdate({
						id,
						args,
						update: draft => {
							draft.metadata.isDefault = newIsDefault
						},
					}),
				)
				try {
					await queryFulfilled
				} catch {
					patchResult.undo()
				}
			},
			invalidatesTags: (result, error, arg) => [{ type: TAG, id: arg.id }],
		}),
		toggleIsActive: builder.mutation<
			ExamTemplateWithId,
			{
				id: string
				newIsActive: boolean
				args: QueryArgs
				templateRole: string
				templateUser: string
			}
		>({
			query: ({ id, newIsActive, templateRole, templateUser }) => {
				const diff: ExamTemplateDiff = {
					metadata: {
						isActive: newIsActive,
						modifiedBy: templateRole,
						modifiedByUser: templateUser,
					},
				}
				return {
					url: `${BASE_URL}/${id}`,
					method: 'PATCH',
					body: { data: diff },
				}
			},
			onQueryStarted: async (
				{ id, newIsActive, args },
				{ dispatch, queryFulfilled },
			) => {
				const patchResult = dispatch(
					optimisticUpdate({
						id,
						args,
						update: draft => {
							draft.metadata.isActive = newIsActive
						},
					}),
				)
				try {
					await queryFulfilled
				} catch {
					patchResult.undo()
				}
			},
			invalidatesTags: (result, error, arg) => [{ type: TAG, id: arg.id }],
		}),
		updateExamTemplate: builder.mutation<
			ExamTemplateWithId,
			{
				id: string
				edited: ExamTemplateDiff
			}
		>({
			query: ({ id, edited }) => {
				return {
					url: `${BASE_URL}/${id}`,
					method: 'PATCH',
					body: { data: edited },
				}
			},
			invalidatesTags: (result, error, arg) => [{ type: TAG, id: arg.id }],
		}),
		getAppointmentTypesBy: builder.query<ReadonlyArray<string>, string>({
			query: legalEntityId => {
				if (!legalEntityId) {
					throw new Error('Cannot search an empty template name')
				}
				return {
					url: `${BASE_URL}/appointment-types?${new URLSearchParams({
						legalEntityId,
					}).toString()}`,
					method: 'GET',
				}
			},
		}),
	}),
})

function invalidateAllTags() {
	return [{ type: TAG, id: ALL_TEMPLATES_ID }]
}

function tagAll(result: ExamTemplateWithId[] | undefined) {
	return result
		? [
				...result.map(({ _id }) => ({ type: TAG, id: _id })),
				{ type: TAG, id: ALL_TEMPLATES_ID },
		  ]
		: [{ type: TAG, id: ALL_TEMPLATES_ID }]
}

const optimisticUpdate = ({
	update,
	id,
	args,
}: {
	id: string
	update: (draft: ExamTemplateWithId) => void
	args: QueryArgs
}) => {
	return examTemplateApi.util.updateQueryData(
		'getTemplatesBy',
		args,
		drafts => {
			const editedDraft = drafts.find(d => d._id === id)
			if (editedDraft) {
				update(editedDraft)
			}
		},
	)
}

export const {
	useAddExamTemplateMutation,
	useDeleteExamTemplatesMutation,
	useUpdateExamTemplateMutation,
	useGetTemplatesByQuery,
	useDoesTemplateNameExistQuery,
	useToggleIsActiveMutation,
	useToggleIsDefaultMutation,
	useGetAppointmentTypesByQuery,
} = examTemplateApi
