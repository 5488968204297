import React, { useEffect } from 'react'
// Used only in main Conncet app
// eslint-disable-next-line telo/no-react-router-import
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'

import AuthenticatedRoute from './AuthenticatedRoute'
import config from './config'
import appActions from './features/app/actions'
import { selectInStore } from './features/app/selectors'
import {
	selectAuthenticationError,
	selectMissingUserError,
	selectUsername,
} from './features/auth/selectors'
import azureCommunicationActions from './features/azureCommunication/actions'
import panelsActions from './features/panels/actions'
import usersActions from './features/users/actions'
import { selectUser } from './features/users/selectors'
import useEclipsMetadata from './hooks/useEclipsMetadata'
import { isGeminiRegion } from './libs/region'
import AuthErrorPage from './pages/AuthErrorPage'
import MissingUserErrorPage from './pages/MissingUserErrorPage'
import NewFhirAppointmentPage from './pages/NewFhirAppointmentPage/NewAppointmentPage'
import {
	LazyAdminInstrumentsInRoomPage,
	LazyAdminPage,
	LazyAdminStoreReferralDoctorPage,
	LazyAppointmentPage,
	LazyCallWidgetWrapper,
	LazyCheckInPageV2,
	LazyEclipsLoginPage,
	LazyEclipsTokenCodePage,
	LazyExamAttributionPage,
	LazyExamPage,
	LazyExamSearchedPage,
	LazyGrantsPage,
	LazyLoginPage,
	LazyLogoutPage,
	LazyNewAppointmentPage,
	LazyNewOrEditPanelPage,
	LazyNewStoreAdminPage,
	LazyNewUserPage,
	LazyPatientAlertsPage,
	LazyPatientList,
	LazyPostLogoutPage,
	LazyPracticeAdminPage,
	LazyProfilePage,
	LazyRefractionistDashboardPage,
	LazyRenewalDashboardPage,
	LazyRenewalSingleTestPage,
	LazyRescheduleAppointmentPage,
	LazyRoomDeviceSelectionPage,
	LazySearchPage,
	LazyStoreAdminPage,
	LazyStoreSelectionPage,
	LazyTokenCodePage,
	LazyUserAdminPage,
	LazyWorklistPage,
} from './routing/lazyRoutes'
import { TeloNavigationFn } from './routing/teloRouter'
import { useGetFeaturesQuery } from './services/features'
import { useTeloDispatch, useTeloSelector } from './store'
import { WithTeloNavigation } from './WithTeloNavigation'

const TeloRoutes = () => {
	const dispatch = useTeloDispatch()
	const navigate = useNavigate()
	const username = useTeloSelector(selectUsername)
	const user = useTeloSelector(selectUser)
	const inStore = useTeloSelector(selectInStore)
	const shouldIFetchUser = user === undefined
	const eclipsRoute = window.location.href.indexOf('eclips') !== -1
	const eclipsMetadata = useEclipsMetadata()
	const skipRenewToken = eclipsMetadata !== undefined || isGeminiRegion === true

	const { data: queueFeature } = useGetFeaturesQuery(
		{
			feature: 'queue',
		},
		{
			skip: !username,
		},
	)

	// we need to start listening to user updates as soon as possible
	// to get exams assigned to the user by the queue when he logs is
	// https://abstractsrl.atlassian.net/browse/TEL-13638
	// we will use the same hook in the worklist to make in work also in the micro frontend version, where we don't use this route component
	// useSyncDataUser(username)

	useEffect(() => {
		queueFeature &&
			dispatch(appActions.setQueueAvailable(queueFeature?.enabled))
	}, [dispatch, queueFeature])

	useEffect(() => {
		if (!username) {
			return
		}

		if (shouldIFetchUser) {
			dispatch(
				usersActions.fetchUser(
					username,
					// TeloNavigationFn type is not exactly equivalent to ReactRouter NavigationFunction
					navigate as TeloNavigationFn,
				),
			).then(user => {
				if (user && user.loggedInAsInStore !== inStore) {
					dispatch(
						usersActions.updateUserInStoreFlag(
							{
								_id: user._id,
								loggedInAsInStore: inStore,
							},
							skipRenewToken === false && !inStore,
						),
					)
				}
				if (user?.role === 'Doctor') {
					dispatch(panelsActions.getDoctorPanels())
				}
				if (user && (user.role === 'Doctor' || user.role === 'Refractionist')) {
					dispatch(azureCommunicationActions.getAzureCommunicationToken())
				}
			})
		}
	}, [
		dispatch,
		username,
		inStore,
		shouldIFetchUser,
		eclipsRoute,
		skipRenewToken,
		navigate,
	])

	const authError = useTeloSelector(selectAuthenticationError)

	const missingUserError = useTeloSelector(selectMissingUserError)

	if (authError) {
		return (
			<WithTeloNavigation>
				<AuthErrorPage />
			</WithTeloNavigation>
		)
	}

	if (missingUserError) {
		return (
			<WithTeloNavigation>
				<MissingUserErrorPage message={missingUserError} />
			</WithTeloNavigation>
		)
	}

	return (
		<Routes>
			<Route
				path="/eclips/init/:username"
				element={
					<WithTeloNavigation>
						<LazyEclipsLoginPage />
					</WithTeloNavigation>
				}
			/>
			<Route
				path="/eclips/app-code"
				element={
					<WithTeloNavigation>
						<LazyEclipsTokenCodePage />
					</WithTeloNavigation>
				}
			/>

			{/* DEPRECATED */}
			<Route
				path="/exam-attribution/:examId"
				element={
					<AuthenticatedRoute>
						<LazyExamAttributionPage />
					</AuthenticatedRoute>
				}
			/>

			<Route
				path="/store/:storeId/patients/:internalPatientId/exams/:examId/current/:currentExamId/:step"
				element={
					<AuthenticatedRoute>
						<LazyExamPage />
						<LazyCallWidgetWrapper />
					</AuthenticatedRoute>
				}
			/>

			<Route
				path="/store/:storeId/patients/:internalPatientId/exams/:examId/:step"
				element={
					<AuthenticatedRoute>
						<LazyExamPage />
						<LazyCallWidgetWrapper />
					</AuthenticatedRoute>
				}
			/>

			<Route
				path="/store/:storeId/appointment/:appointmentId"
				element={
					<AuthenticatedRoute>
						<LazyAppointmentPage />
					</AuthenticatedRoute>
				}
			/>

			<Route
				path="/store/:storeId/check-in/exam/:examId/patient/:internalPatientId"
				element={
					<AuthenticatedRoute>
						<LazyCheckInPageV2 />
					</AuthenticatedRoute>
				}
			/>

			<Route
				path="/login"
				element={
					<WithTeloNavigation>
						<LazyLoginPage />
					</WithTeloNavigation>
				}
			/>
			<Route
				path="/logout"
				element={
					<WithTeloNavigation>
						<LazyLogoutPage />
					</WithTeloNavigation>
				}
			/>
			<Route
				path="/postlogout"
				element={
					<WithTeloNavigation>
						<LazyPostLogoutPage />
					</WithTeloNavigation>
				}
			/>
			<Route
				path="/store/:storeId/patient-history/:internalPatientId"
				element={
					<AuthenticatedRoute>
						<LazyExamSearchedPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/room-device"
				element={
					<AuthenticatedRoute>
						<LazyRoomDeviceSelectionPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/store-selection"
				element={
					<AuthenticatedRoute>
						<LazyStoreSelectionPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/store/:storeId/worklist"
				element={
					<AuthenticatedRoute>
						<LazyWorklistPage />
					</AuthenticatedRoute>
				}
			/>

			<Route
				path="/store/:storeId/search"
				element={
					<AuthenticatedRoute>
						<LazySearchPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/store/:storeId/patient-list"
				element={
					<AuthenticatedRoute>
						<LazyPatientList />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/store/:storeId/new-appointment"
				element={
					<AuthenticatedRoute>
						{config.featuresSwitch.directAccess === 'fhir' && (
							<NewFhirAppointmentPage />
						)}
						{config.featuresSwitch.directAccess === 'tab' && (
							<LazyNewAppointmentPage />
						)}
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/store/:storeId/appointment/:appointmentId/edit"
				element={
					<AuthenticatedRoute>
						<LazyRescheduleAppointmentPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/doctor/exam/:examId/current/:currentExamId"
				element={
					<AuthenticatedRoute>
						<LazyExamPage />
						<LazyCallWidgetWrapper />
					</AuthenticatedRoute>
				}
			/>

			<Route
				path="/doctor/exam/:examId"
				element={
					<AuthenticatedRoute>
						<LazyExamPage />
						<LazyCallWidgetWrapper />
					</AuthenticatedRoute>
				}
			/>

			<Route
				path="/refractionist/dashboard"
				element={
					<AuthenticatedRoute>
						<LazyRefractionistDashboardPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/doctor/worklist"
				element={
					<AuthenticatedRoute>
						<LazyWorklistPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/doctor/renewal/dashboard"
				element={
					<AuthenticatedRoute>
						<LazyRenewalDashboardPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/doctor/search"
				element={
					<AuthenticatedRoute>
						<LazySearchPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/doctor/renewal/test/:examId"
				element={
					<AuthenticatedRoute>
						<LazyRenewalSingleTestPage />
						<LazyCallWidgetWrapper />
					</AuthenticatedRoute>
				}
			/>

			<Route
				path="/app-code"
				element={
					<WithTeloNavigation>
						<LazyTokenCodePage />
					</WithTeloNavigation>
				}
			/>
			<Route path="/grants" element={<LazyGrantsPage />} />
			<Route
				path="/admin/store/:storeId/settings/stage/:stageId/room/:roomId"
				element={
					<AuthenticatedRoute>
						<LazyAdminInstrumentsInRoomPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/store/:storeId"
				element={
					<AuthenticatedRoute>
						<LazyWorklistPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/admin"
				element={
					<AuthenticatedRoute>
						<LazyAdminPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/admin/new-store"
				element={
					<AuthenticatedRoute>
						<LazyNewStoreAdminPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/admin/new-panel"
				element={
					<AuthenticatedRoute>
						<LazyNewOrEditPanelPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/admin/panel/:panelId"
				element={
					<AuthenticatedRoute>
						<LazyNewOrEditPanelPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/admin/new-practice"
				element={
					<AuthenticatedRoute>
						<LazyPracticeAdminPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/admin/practice/:practiceId"
				element={
					<AuthenticatedRoute>
						<LazyPracticeAdminPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/admin/user/:username"
				element={
					<AuthenticatedRoute>
						<LazyUserAdminPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/admin/new-user"
				element={
					<AuthenticatedRoute>
						<LazyNewUserPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/admin/store/:storeId/referral-doctor/:referralDoctorId"
				element={
					<AuthenticatedRoute>
						<LazyAdminStoreReferralDoctorPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/admin/store/:storeId/new-referral-doctor"
				element={
					<AuthenticatedRoute>
						<LazyAdminStoreReferralDoctorPage isCreate={true} />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/admin/store/:storeId"
				element={
					<AuthenticatedRoute>
						<LazyStoreAdminPage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/profile"
				element={
					<AuthenticatedRoute>
						<LazyProfilePage />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/doctor/exam/:examId/patient/:patientId/alerts"
				element={
					<AuthenticatedRoute>
						<LazyPatientAlertsPage />
						<LazyCallWidgetWrapper />
					</AuthenticatedRoute>
				}
			/>
			<Route
				path="/doctor/exam/:examId/current/:currentExamId/patient/:patientId/alerts"
				element={
					<AuthenticatedRoute>
						<LazyPatientAlertsPage />
						<LazyCallWidgetWrapper />
					</AuthenticatedRoute>
				}
			/>

			<Route path="/" element={<Navigate to="/login" />} />
		</Routes>
	)
}

export default TeloRoutes
